<template>
  <div class="pb-32">

    <h2 class="text-xl font-bold mb-10">
      Statistics for Businesses
    </h2>

    <template v-if="stats.loading">
      <loader />
    </template>
    <template v-else>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4" style="margin-top: 1px; margin-left: 1px">
        <template v-for="(stat, n) in stats.data">
          <div class="col-span-1 p-6 border" style="margin-left: -1px; margin-top: -1px;" :key="n">
            <div class="text-sm font-thin text-gray-900 mb-3">{{ stat.key | fromSlug | sentenceCase }}</div>
            <div class="text-sm font-bold" v-if="stat.type == 'amount'">₦ {{ stat.value | currency }}</div>
            <div class="text-sm font-bold" v-else-if="stat.type == 'percentage'">{{ stat.value }} %</div>
            <div class="text-sm font-bold" v-else>{{ stat.value }}</div>
          </div>
        </template>
      </div>
    </template>
    
  </div>
</template>

<script>
  export default {
    name: 'RepaymentStatistics',
    data() {
      return {
        stats: this.$options.resource([]),
      }
    },
    beforeMount() {
      this.getStats();
    },
    methods: {
      async getStats() {
        this.stats.loading = true;

        await this.sendRequest('admin.companies.statistics', {
          success: response => {
            this.stats.data = response.data.statistics;
          },
          error: error => {
            this.stats.error = error;
          }
        });

        this.stats.loading = false;
      }
    }
  }
</script>